/** @jsx jsx */
import { jsx } from 'theme-ui'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Row, Col } from 'gatsby-theme-landmarks-core'

const Logos = () => {
    const data = useStaticQuery(query)
    const {
        Elispavaje,
        Cemacon,
        Baumit,
        Rehau,
        Buderus,
        Ceresit,
        Mapei,
        Geberit,
        Schueco,
        Legrand,
        Bauder,
        Atria,
        Xella,
        Portadoors,
        Amano,
        format4,
        Kone,
        Perpetuum
    } = data
    return (
      <div>
        <Row
            gap={[2, 3]}
            sx={{ alignItems: 'center' }}
            colSx={{
              display: 'flex',
                justifyContent: 'center',
                mb: 4,
              }}
        >
            <Col>
            <a href="https://format4.ro/">
                <Img fluid={format4.childImageSharp.fluid} sx={{ width: '80px', px: 1 }} />
            </a>
            </Col>
            <Col>
                <Img fluid={Ceresit.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
            </Col>
            <Col>
                <Img fluid={Mapei.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
            </Col>
          <Col>
            <Img fluid={Amano.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
          </Col>
            <Col>
                <Img fluid={Buderus.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
            </Col>
            <Col>
                <Img fluid={Baumit.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
            </Col>
        </Row>

        <Row
            gap={[2, 3]}
            sx={{ alignItems: 'center' }}
            colSx={{
              display: 'flex',
                justifyContent: 'center',
                mb: 4,
              }}
        >
            <Col>
                <Img fluid={Cemacon.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
            </Col>
          <Col>
            <Img fluid={Atria.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
          </Col>
          <Col>
            <Img fluid={Kone.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
          </Col>
          <Col>
            <Img fluid={Perpetuum.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
          </Col>
          <Col>
          <Img fluid={Rehau.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
        </Col>
        <Col>
          <Img fluid={Geberit.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
        </Col>
        </Row>
        
      <Row
        gap={[2, 3]}
        sx={{ alignItems: 'center' }}
        colSx={{
          display: 'flex',
          justifyContent: 'center',
          mb: 4,
        }}
      >
        <Col>
          <Img fluid={Elispavaje.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
        </Col>
          <Col>
            <Img fluid={Portadoors.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
          </Col>
        <Col>
            <Img fluid={Schueco.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
        </Col>
          <Col>
            <Img fluid={Xella.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
          </Col>
        <Col>
            <Img fluid={Legrand.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
        </Col>
        <Col>
            <Img fluid={Bauder.childImageSharp.fluid} sx={{ width: '120px', px: 1 }} />
        </Col>
      </Row>
      </div>
    )
}

export default Logos

const query = graphql`
  {
    Elispavaje: file(name: { eq: "Elis-Pavaje" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Cemacon: file(name: { eq: "Cemacon" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Baumit: file(name: { eq: "Baumit" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Rehau: file(name: { eq: "Rehau" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Buderus: file(name: { eq: "Buderus" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Ceresit: file(name: { eq: "Ceresit" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Mapei: file(name: { eq: "Mapei" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Geberit: file(name: { eq: "Geberit" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
      Schueco: file(name: { eq: "Schueco" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
      Amano: file(name: { eq: "Amano" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
      Legrand: file(name: { eq: "Legrand" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
      Bauder: file(name: { eq: "Bauder" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

          Atria: file(name: { eq: "Atria" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
        Xella: file(name: { eq: "Xella" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
          Portadoors: file(name: { eq: "Portadoors" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    format4: file(name: { eq: "format4" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    Perpetuum: file(name: { eq: "Perpetuum" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    Kone: file(name: { eq: "Kone" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
  }
`
