/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useState } from 'react'
import {Row, Col} from 'gatsby-theme-landmarks-core'

export const Collapse = props => {
    const [isOpen, setIsOpen] = useState(false)
    
    return (
        <div onClick={() => setIsOpen(!isOpen)}>
            <div sx={{
                backgroundColor: 'primary',
                color: 'white',
                fontSize: 4,
                fontWeight: 'bold',
                cursor: 'pointer',
                p: 3,
                m: 0,
                ':hover': {
                    opacity: .9
                }
            }}>
                <Row>
                    <Col sx={{
                        fontSize:3
                    }}>
                        {props.title}
                    </Col>
                    <Col sx={{
                        textAlign:'right',
                        paddingLeft: 2
                    }}>

                        {isOpen ? '-' :'+'}
                    </Col>
                </Row>
                </div>
            <div sx={{
                px: [0,3],
                py: 1,
                background:'white',
                color:'black'
            }}>
                {isOpen && props.children}
            </div>
        </div>
    )
}
export default Collapse