/** @jsx jsx */
import { jsx } from "theme-ui";
import Collapse from "../components/Collapse/CollapseSigns";
import Img from "gatsby-image";
import React from "react";
import Logos from "../components/Logos/Logos";
import logo from "../images/site/Scala-Center-horizontal.png";

import Header from "../sections/Header/Header";
import Footer from "../sections/Footer/Footer";
import { Helmet, Container, H3, H1, Row, Col } from "gatsby-theme-landmarks-core";

const Despre = (props) => {
  const { data } = props;
  const { despreansamblunouscala, hartaDesktop } = data;
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Scala Frunzisului | Ansamblu rezidential in Cluj-Napoca"
        />
        <meta name="description" content="Despre Proiectul Scala Frunzisului" />
        <title>Despre Proiectul Scala Frunzisului</title>
      </Helmet>
      <Header logoSrc={logo} logoAlt="Scala Frunzisului" />
      <Container
        sx={{
          pt: [6, "200px"],
          pb: 4,
        }}
      >
        <H1

               sx={{
                 display: ["none", "block"],
                 pl: 4,
                 textAlign: 'center'
               }}
             >
               Despre Proiect
             </H1>
        <H3
          sx={{
            display: ["block", "none"],
            px: 3,
          }}
        >
          Despre Proiect
        </H3>
        <Row>
          <Col
            sx={{
              px: [1, 4],
              pt: [0, 4],
            }}
          >
            <Img
              fluid={despreansamblunouscala.childImageSharp.fluid}
              sx={{
                height: "100vh",
                zIndex: 0,
              }}
            />
          </Col>
          <Col
            sx={{
              width: ["100vw", 1],
              px: [1, 4],
              py: 4,
            }}
          >
     
            <Collapse title="FUNDAȚIE SI STRUCTURĂ">
              <ul
                sx={{
                  background: "white",
                }}
              >
                <li>Radier general susținut de piloți forați.</li>
                <li>
                  Structura de rezistență este realizată din cadre de beton
                  armat cu plăci dală, astfel eliminând grinzile inestetice din
                  interiorul apartamentelor.
                </li>
                <li>
                  Acoperișul de tip terasă va avea în componență: termoizolație
                  cu caracteristici superioare de 20cm, un sistem de
                  hidroizolare <a href='https://www.bauder.ro/ro/acoperis-terasa/descarcari/fise-tehnice/bituminoase.html?no_cache=1' target="_blank">BAUDER</a> compus din barieră vapori și difuzie, un
                  strat membrană bituminoasă și un strat final membrană
                  bituminoasă cu ardezie.
                </li>
                <li>
                  Pereții exteriori sunt realizați din cărămidă de 24/25 cm
                  grosime cu termoizolație de 10/15 cm, realizată din <a href='https://www.ytong.ro/multipor_1725.php' target="_blank">Multipor</a> de la XELLA, vată minerală bazaltică şi un sistem de tencuieli
                  decorative silicatice de la <a href="https://baumit.ro/products/finisaje-pentru-fatade/tencuieli-decorative/silikontop" target="_blank">BAUMIT.</a>
                </li>
                <li>
                  Întreaga fațadă are clasă de reacție la foc A1 cu agrementare
                  din partea producătorilor, fiind certificată ca
                  incombustibilă.
                </li>
                <li>
                  Pereții interiori ce delimitează apartamentele pe nivel sunt
                  realizați din cărămidă de 24/25cm grosime, având un grad
                  ridicat de fonoizolare.
                </li>
              </ul>
            </Collapse>
            <Collapse title="TÂMPLĂRIE">
              <ul
                sx={{
                  background: "white",
                }}
              >
                <li>
                  Ușile de intrare în apartament <a href="https://static.porta.com.pl/documents/katalog_porta_2020_ro.pdf#page=146" target="_blank">PORTADOORS</a> au următoarele
                  specificaţii: antiefracție, izolație fonică 32-36 dB,
                  feronerie balamale, prag și sistem de închidere din inox.
                </li>
                <li>
                  Tâmplarie exterioară aluminiu SCHUCO (Lideri europeni în
                  tâmplărie de aluminiu) cu sticlă tripan (pachet 46mm), barieră
                  termică, protecție solară, izolație fonică minimum 32dB.
                </li>
              </ul>
            </Collapse>
            <Collapse title="FINISAJE INTERIOARE">
              <ul>
                <li>
                  Pardoseală interioară apartament la stadiul semifinisat – șapă
                  turnată.
                </li>
                <li>
                  Pereți interiori apartament:
                  <ul>
                    <li>tencuială mecanizată <a href="https://baumit.ro/products/tencuieli-si-tinciuri/tencuieli-mecanizate/mpi-25" target="_blank">BAUMIT</a>;</li>
                    <li>glet mecanizat cu un strat, marca <a href="https://baumit.ro/products/tencuieli-decorative-vopsele-si-gleturi/gleturi/finogrande" target="_blank">BAUMIT</a>.</li>
                  </ul>
                </li>
                <li>
                  Măşti ghips-carton dublu placate cu vata <a href="https://www.knaufinsulation.ro/produse/decibel"target="_blank">Knauf Decibel</a>.
                </li>
              </ul>
            </Collapse>
            <Collapse title="BALCOANE SI TERASE">
              <ul>
                <li>
                  Finisarea balcoanelor este realizată cu pardoseală ceramică
                  antiderapantă cu plăci de dimensiuni mari.
                </li>
                <li>
                  Balustradele sunt zincate și vopsite în câmp electrostatic,
                  pentru o anduranță cât mai mare.
                </li>
              </ul>
            </Collapse>
            <Collapse title="INSTALAȚII SANITARE">
              <ul>
                <li>Sistem complet de instalații REHAU.</li>
                <li>
                  Coloane canalizare menajera tip <a href="https://www.geberit.com.au/products/piping-systems/geberit-hdpe-discharge-pipes/" target="_blank">GEBERIT</a> din polietilenă.
                </li>
                <li>
                  Coloane separate pentru preluare condens CT și aparate de aer
                  condiționat.
                </li>
                <li>
                  Coloanele de canalizare pluvială sunt cu montaj la exterior în
                  balcon, evitând astfel riscul de a se produce
                  inundații/infiltrații în casă și sunt ambientate pentru un
                  aspect modern.
                </li>
                <li>
                  Casa scării este echipată cu coloană de stingere incendiu cu
                  hidranți + coloane uscate.
                </li>
              </ul>
            </Collapse>
            <Collapse title="INSTALAȚII TERMICE">
              <ul>
                <li>Sistem complet încălzire în pardoseală <a href="https://www.rehau.com/sites-ro/incalzire-pardoseala" target="_blank">REHAU</a>.</li>
                <li>
                  Garanție sistem REHAU 10 ani cu asigurare pe proiect și
                  despăgubiri între 500.000-1.000.000 de euro.
                </li>
                <li>Centrală termică <a href="https://www.buderus.com/tr/en/ocs/residential-products/logamax-plus-gb022-993617-p/" target="_blank">BUDERUS</a> în condensație.</li>
                <li>Termostat wireless programabil.</li>
                <li>
                  Opțional se poate instala automatizarea încălzirii pe zone.
                </li>
              </ul>
            </Collapse>
            <Collapse title="INSTALAȚII ELECTRICE">
              <ul>
                <li>Tablouri electrice echipate cu siguranțe LEGRAND.</li>
                <li>
                  Toate circuitele electrice sunt executate cu cablu CYY-f
                  (cablu antifoc) care întârzie propagarea flăcării.
                </li>
                <li>Echipare lampă LED balcon.</li>
                <li>Sistem videointerfonie color marca LEGRAND.</li>
                <li>Fibră optică până la intrarea în apartamente.</li>
                <li>Sistem Smart Home Ready.</li>
              </ul>
            </Collapse>
            <Collapse title="INSTALAȚII VENTILARE">
              <ul>
                <li>
                  Coloane ventilație băi din tablă zincată echipată cu
                  ventilator cu clapetă de sens și temporizator în baie,
                  inclusiv ventilatoare de extracție pe învelitoare cu
                  funcționare temporizată, asigurând astfel o ventilaţie
                  corespunzătoare.
                </li>
                <li>
                  Coloane ventilație bucătării echipate cu releu de comandă
                  aferent hotei, inclusiv ventilatoare de extracție pe
                  invelitoare, cu funcționare temporizată.
                </li>
                <li>
                  Casele de scară și lifturile au aport de aer proaspăt și
                  instalații de presurizare în caz de incendiu.
                </li>
                <li>
                  Opțional - apartamentele se pot echipa cu câte un aparat de
                  aer condiționat.
                </li>
              </ul>
            </Collapse>
            <Collapse title="INSTALAȚII SUBSOL">
              <ul>
                <li>
                  Instalații iluminat full LED şi senzori mișcare, iluminat de
                  siguranță.
                </li>
                <li>Instalații detecție incendiu.</li>
                <li>
                  Instalații stingere incendiu cu sprinklere, drencere și
                  hidranți.
                </li>
                <li>Instalații desfumare în caz de incendiu.</li>
                <li>
                  Instalații presurizare casa scării subsol în caz de incendiu.
                </li>
                <li>Instalație detecție, diluţie și evacuare noxe auto.</li>
              </ul>
            </Collapse>
            <Collapse title="PARCĂRI">
              <ul>
                <li>5 sisteme de parcare automată.</li>
                <li>155 de parcări subterane dispuse pe 3 niveluri.</li>
                <li>12 parcări exterioare.</li>
                <li>163 de parcări pentru biciclete.</li>
                <li>
                  Parcările sunt echipate cu barieră AMANO iar accesul în
                  parcare se face cu telecomandă.
                </li>
                <li>Pardoseli din beton elicopterizat cu cuarţ.</li>
                <li>Semnalizare rutieră adecvată.</li>
              </ul>
            </Collapse>
            <Collapse title="CASA SCĂRII">
              <ul>
                <li>
                  Finisarea spațiilor comune se va realiza cu pardoseală
                  ceramică cu clasa de aderență R11 (aderență ridicată).
                </li>
                <li>
                  Ușa de intrare în clădire este realizată din tâmplărie de
                  aluminiu de înaltă calitate SCHUCO.
                </li>
                <li>Control acces/videointerfonie LEGRAND.</li>
                <li>
                  Două ascensoare de mare viteză (minimum 1,6 mps) de
                  proveniență Elvețiană sau Finlandeză, cu capacitate de 8-9
                  persoane.
                </li>
              </ul>
            </Collapse>
            <Collapse title="FACILITĂȚI ANSAMBLU">
              <ul>
                <li>ansamblu rezidențial securizat.</li>
                <li>sistemul de iluminat al clădirii cu LED.</li>
                <li>
                  3000 mp de spații verzi şi alei de promenadă cu vegetație
                  matură.
                </li>
                <li>Zonă de joacă pentru copii.</li>
                <li>Zonă evacuare deșeuri securizată.</li>
              </ul>
            </Collapse>
            <Collapse title="ETAJ RETRAS">
              <ul>
                <li>
                  Luminatoare 1x2m în zona de zi acționate electric cu senzor de
                  închidere.
                </li>
                <li>Tâmplărie cortină.</li>
                <li>Încălzire în pardoseală cu kit de pompare.</li>
                <li>
                  Terase circulabile generoase prevăzute cu pardoseală ceramică
                  flotantă.
                </li>
                <li>Zonă evacuare deșeuri securizată.</li>
                <li>Balustrăzi de sticlă securizată EDGES.</li>
                <li>
                  Separatoare între terase din lemn de esenţă tare, ambientate
                  cu vegetaţie naturală.
                </li>
                <li>
                  Terasele dispun de instalaţie electrică/sanitară separată,
                  pentru Hot Tube, Jacuzzi sau mini piscină încălzită.
                </li>
              </ul>
            </Collapse>
            <br />
          </Col>
        </Row>
        <br></br>
        <Logos></Logos>
      </Container>
      <Footer />
    </>
  );
};

export const query = graphql`
  {
    hero1: file(name: { eq: "hero1" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    hartaDesktop: file(name: { eq: "harta-scala" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    hartaMobile: file(name: { eq: "harta-mobile" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
    despreansamblunouscala: file(name: { eq: "despre-ansamblu-nou-scala" }) {
      childImageSharp {
        fluid(maxWidth: 1923, maxHeight: 1280, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
          presentationHeight
        }
      }
    }
  }
`;

export default Despre;
